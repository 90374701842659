import { useState, createRef } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import './ContactUs.css'
import Helmet from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import Logo from '../../assets/Logo.png';


const ContactUs = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [captchaError, setCaptchaError] = useState('');
  const [token, setToken] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);

  const MIN_MESSAGE_LENGTH = 60; // Minimum number of characters for the message

  const sendEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const message = form.from_message.value.trim();

    if (form.checkValidity() === false || !captchaValid || message.length < MIN_MESSAGE_LENGTH) {
      e.stopPropagation();
      setValidated(true);
      if (message.length < MIN_MESSAGE_LENGTH) {
        setMessageError(`Message must be at least ${MIN_MESSAGE_LENGTH} characters long.`);
      }
      return;
    }
    setValidated(true);

    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    setLoading(true);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbyEmgnxyNr6GLJl-1E5T_EXnCQViSyyW7q8yvBd0BruKP8QiADlOOWY31AAPM_1l4DbCQ/exec', {
        redirect: "follow",
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        }
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'success') {
          setSuccessMessage(true);
        } else {
          setSuccessMessage(false);
          console.error('Error:', result.message);
        }
      } else {
        setSuccessMessage(false);
        console.error('Network response was not ok:', response.statusText);
      }
    } catch (error) {
      setSuccessMessage(false);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const reCaptchaRef = createRef();
  const handleRecaptchaChange = (token) => {
    if (!token) {
      setCaptchaError("Please complete the reCAPTCHA");
      setCaptchaValid(false);
    } else {
      setCaptchaError("");
      setToken(token);
      setCaptchaValid(true);
    }
  };

  const handleRecaptchaExpired = () => {
    console.log("handleRecaptchaExpired");
    setToken("");
    reCaptchaRef.current.reset();
    setCaptchaValid(false);
  };

  const handleMessageChange = (e) => {
    const message = e.target.value.trim();
    if (message.length >= MIN_MESSAGE_LENGTH) {
      setMessageError('');
    } else {
      setMessageError(`Message must be at least ${MIN_MESSAGE_LENGTH} characters long.`);
    }
  };

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Contact - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Reach out to Tensor Pilot's support team for expert assistance with our AI-powered drone software. Whether you're in enterprise, education, or retail, we're here to help with any drone-related inquiries." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Tensor Pilot, Contact Tensor Pilot, AI Drone Software Support, Enterprise Drones, Education Drones, Retail Drones, Precision Flying Support, AI Drone Inquiries, Drone Software Support" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Contact Tensor Pilot - AI Drone Software for Enterprise, Education, and Retail Drones" />
        <meta property="og:description" content="Get in touch with Tensor Pilot's team for support on our AI-driven drone software. Ideal for enterprise, education, and retail sectors, our software enhances drone performance and analysis." />
        <meta property="og:url" content="https://www.aidronesoftware.com/contactus" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Tensor Pilot - AI Drone Software" />
        <meta name="twitter:description" content="Whether you're using drones in enterprise, education, or retail, Tensor Pilot's AI software is designed to enhance your operations. Contact us for more information and support." />
        <meta name="twitter:image" content={Logo} />

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <NavBarMain />
      <div className="full-bg">
        <Container>
          <div className="glass-container">
            <center>
              <h3>Contact Us</h3>
              <p>We aim for 100% customer & product satisfaction.</p>
            </center>

            {successMessage ? (
              <center>
                <Alert variant="success">
                  <p>Your request was received! We will reach out to you soon. If you need urgent help, please reach us at support@aidronesoftware.com.</p>
                </Alert>
                <div>
                  <Button 
                    variant="outline-primary" 
                    onClick={() => setSuccessMessage(false)}>
                      Submit Another Request
                  </Button>
                  <Button 
                    className='ml-2'
                    variant="outline-primary" 
                    href="/profile">
                      Take Me to Profile
                  </Button>
                </div>
              </center>
            ) : (
              <Form noValidate validated={validated} onSubmit={sendEmail}>
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.first_name">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control 
                        required
                        placeholder="First name" 
                        name="first_name" 
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a first name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="exampleForm.second_name">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control 
                        required
                        placeholder="Last name" 
                        name="second_name" 
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a last name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Your Email address</Form.Label>
                  <Form.Control 
                    required
                    type="email" 
                    name="from_email" 
                    placeholder="name@example.com" 
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Please Select Your Reason</Form.Label>
                  <Form.Control as="select" name="from_subject" required>
                    <option value="">Choose...</option>
                    <option>Enterprise</option>
                    <option>School & Education</option>
                    <option>Application Installation</option>
                    <option>Technical Support</option>
                    <option>Buy Tensor Pilot Pro</option>
                    <option>Investor Relations</option>
                    <option>General Inquiry</option>
                    <option>Partnership Opportunities</option>
                    <option>Product Feedback</option>
                    <option>Billing and Payments</option>
                    <option>Account Management</option>
                    <option>Unsubscribe me from marketing & product update mails</option>
                    <option>Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a reason for your inquiry.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control 
                    required
                    as="textarea" 
                    name="from_message" 
                    rows={3} 
                    isInvalid={messageError !== ''}
                    onChange={handleMessageChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a message.
                  </Form.Control.Feedback>
                  {messageError && (
                    <div className="text-danger">
                      {messageError}
                    </div>
                  )}
                </Form.Group>
                <center>
                  <ReCAPTCHA
                    className="mt-5"
                    ref={reCaptchaRef}
                    sitekey="6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr"
                    onChange={handleRecaptchaChange}
                    onExpired={handleRecaptchaExpired}
                  />
                  {captchaValid ?
                    <Form.Control.Feedback type="invalid" style={{ display: captchaValid ? 'none' : 'block' }}>
                      Please complete the CAPTCHA.
                    </Form.Control.Feedback>
                    :
                    <></>
                  }
                  <Button
                    className="btn-lg mt-3" 
                    variant="outline-primary" 
                    value="Send" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> 
                        Loading...
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </center>
              </Form>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
