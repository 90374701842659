import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar'
import Youtubeplayer from '../../sharedcomponents/youtubeplayer/youtubeplayer'
import {Button} from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeadset, faUniversalAccess, faLightbulb, faVideo, faStar } from '@fortawesome/free-solid-svg-icons'
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AIFeaturesDisplay from './AIFeaturesDisplay';
import CinematographyFeatures from './CinematographyFeatures';
import DroneControlOptions from'./DroneControlOptions'
import UserTypesSection from './UserTypesSection'
import CallToActionSection from './CallToActionSection'
import FAQ from './FAQ'
import Helmet from 'react-helmet';
import Logo from '../../assets/Logo.png';

import './css/Home.css'

library.add(faApple, faWindows, faHeadset, faUniversalAccess, faLightbulb, faVideo, faStar)


function TypingEffect() {
    const [i, setI] = useState(0);
    const txt = 'Tensor Pilot: Where Imagination Takes Flight';
    const speed = 50;

    useEffect(() => {
        const typeWriter = () => {
            if (i < txt.length) {
                setI((prevI) => prevI + 1);
            }
        };

        const interval = setInterval(typeWriter, speed);

        return () => clearInterval(interval);
    }, [i]);

    const isTypingComplete = i !== txt.length;

    return (
        <div className="gradient-text">
            {txt.slice(0, i)}
            {isTypingComplete && <span className="cursor">|</span>}
        </div>
    );
}

const Home = () => {
    const demoRef = useRef(null);

    const handleShowDemo = () => {
        demoRef.current.scrollIntoView({ behavior: 'smooth' });
    };



    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>Home - Tensor Pilot</title>

                {/* Meta Description for SEO */}
                <meta name="description" content="Explore Tensor Pilot AI Drone Software for cutting-edge solutions in autonomous flying, real-time object detection, and advanced data analytics. Perfect for drones in enterprise, education, and retail sectors." />

                {/* SEO Keywords */}
                <meta name="keywords" content="Tensor Pilot, AI Drone Software, Autonomous Flying, Real-Time Object Detection, Drone Analytics, Enterprise Drone Solutions, AI Drone Technology, Drone Education, Drone Retail, Human Pose Detection Drones, Object Tracking Drones" />

                {/* Open Graph Meta Tags for Social Media */}
                <meta property="og:title" content="Home - Tensor Pilot" />
                <meta property="og:description" content="Discover Tensor Pilot AI Drone Software with advanced features for autonomous flying, surveillance, and real-time analytics. Ideal for enterprise, education, and retail drones." />
                <meta property="og:url" content="https://www.aidronesoftware.com/" />
                <meta property="og:site_name" content="Tensor Pilot AI Drone Software" />
                <meta property="og:image" content={Logo} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Tensor Pilot an AI Drone Software" />
                <meta name="twitter:description" content="Tensor Pilot AI Drone Software provides advanced AI solutions for autonomous flying, surveillance, and data analytics, designed for enterprise, education, and retail drones." />
                <meta name="twitter:image" content={Logo} />

                {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            
            <script src="https://js.storylane.io/js/v1/storylane.js"></script>
                                
            <NavBarMain />
            <Container fluid className="col-lg-8 col-md-10 col-11 home-container">

                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <h1 className="gradient-text display-3">
                            <TypingEffect />
                        </h1>

                        <div className="home-subheader pl-3">
                            <h5 className="text-muted">
                            AI Drone Software specializes in desktop applications that elevate drone performance by offloading complex computations from drones to computers, enhancing capabilities across all drone markets. Our software, which is free to download, supports both Mac and Windows platforms, providing robust tools for diverse drone operations.
                            </h5>
                        </div>

                        <div className="custom-buttons-container my-4">
                            <Button 
                                variant="outline-primary"
                                href="/profile"
                                className="btn btn-light btn-lg border-1 custom-download-button mx-2 my-2" >
                                <b>
                                <FontAwesomeIcon icon={['fab', 'windows']} />  <FontAwesomeIcon icon={['fab', 'apple']} />  Download 
                                </b>
                            </Button>

                            <Button 
                                className="btn btn-lg center btn-primary mx-2  my-2" 
                                aria-label="Download for Apple"
                                onClick={handleShowDemo}
                            >
                                <b> Watch demo </b>
                            </Button>
                        </div>

                    </Col>

                    <Col lg={12} md={12} sm={12} className="my-3 text-center">
                        <center>
                            <div className="iframe-storylane-box" ref={demoRef}>
                            <iframe
                                src="https://app.storylane.io/demo/abhyadrrxvil"
                                name="sl-embed"
                                allow="fullscreen"
                                className="sl-demo iframe-storylane"
                                style={{ border: 'none', overflow: 'hidden' }} 
                            />
                            </div>
                        </center>
                    </Col>
                </Row>

                <Row>
                    <div>
                        <CinematographyFeatures />            
                        <AIFeaturesDisplay />
                        <DroneControlOptions />
                    </div>
                </Row>
                
                {/* Component of the drone video */}
                {/* <Row className="justify-content-center">
                    <Col md={10} lg={9} sm={12} className="text-center mt-5">
                        <h3 className="mb-3">
                            Drone AI In Action
                        </h3>
                        <div className="video-container">
                            <Youtubeplayer 
                                url="https://www.youtube.com/watch?v=CjnzPeUDUGw" 
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </Col>
                </Row> */}

                <Row className="justify-content-center">
                    <div>
                        <UserTypesSection />
                        <FAQ />
                        <CallToActionSection />
                    </div>
                </Row>        
            </Container>
        </>
    )
}


export default Home;