import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faKeyboard, faGamepad } from '@fortawesome/free-solid-svg-icons';
import './css/ControlMenu.css'

library.add(faKeyboard, faGamepad);

const ControlsMenu = ({ selectedControl }) => {
    const [selectedSetting, setSelectedSetting] = useState(selectedControl);

    const keyboardCommandDescriptions = {
        "ArrowUp": "Move Forward",
        "ArrowDown": "Move Backward",
        "ArrowLeft": "Turn Left",
        "ArrowRight": "Turn Right",
        "KeyW": "Ascend",
        "KeyS": "Descend",
        "KeyA": "Yaw Left",
        "KeyD": "Yaw Right",
        "KeyE": "Take Off",
        "KeyQ": "Land",
        "KeyX": "Emergency Stop",
        "Enter": "Connect",
        "Digit1": "Object Detection",
        "Digit2": "Pose Detection Mode",
        "Digit3": "Face Mesh",
        "Digit5": "Hand Gesture",
        "Digit6": "Record Video",
        "Digit7": "Burst Mode",
        "Digit8": "Timer",
        "Equal": "Increase Speed",
        "Minus": "Decrease Speed"
    };

    const gamepadCommandDescriptions = {
        "RB + Y": "Take Off",
        "RB + A": "Land",
        "RB + X": "Emergency Landing",
        "LT + Y": "Activate Object Detection",
        "LT + B": "Activate Pose Detection",
        "LT + A": "Activate Face Tracking",
        "LT + X": "Activate Object Tracking",
        "LB + A": "Activate Burst Mode",
        "LB + Y": "Start/Stop Recording Video",
        "LB + B": "Capture Photo",
        "LB + X": "Set Timer for Photo",
        "Hat Up/Right": "Increase Drone Speed",
        "Hat Down/Left": "Decrease Drone Speed",
        "Connect Button": "Connect to Drone",
        "RT": "Activate/Deactivate Selected Feature"
    };

    const renderCommandMappings = (commandDescriptions) => {
        return (
            <table className="table table-custom table-hover">
                <thead>
                    <tr>
                        <th>Button Combination</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(commandDescriptions).map(([key, desc]) => (
                        <tr key={key}>
                            <td><strong>{key}</strong></td>
                            <td>{desc}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const renderSettingDetails = () => {
        switch (selectedSetting) {
            case 'Keyboard':
                return (
                    <div className="setting-details">
                        {renderCommandMappings(keyboardCommandDescriptions)}
                    </div>
                );
            case 'Gamepad':
                return (
                    <div className="setting-details">
                        {renderCommandMappings(gamepadCommandDescriptions)}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="settings-container">
            <div className="settings-content">
                {renderSettingDetails()}
            </div>
        </div>
    );
};

export default ControlsMenu;
