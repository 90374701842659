import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import RelatedArticles from './RelatedArticles';
import Helmet from 'react-helmet';
import { storage } from '../../firebase';
import BlogPostBottom from './BlogPostBottom';
import './css/BlogPost.css';

const BlogPost = () => {
  const { id } = useParams();
  const history = useHistory();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, [id]);

  useEffect(() => {
    setBlog(null);
    setLoading(true);
    setError(false);
    setRelatedArticles([]);
    const fetchBlogData = async () => {
      try {
        const blogRef = storage.ref(`Blog/${id}/${id}.json`);
        const url = await blogRef.getDownloadURL();
        const response = await fetch(url);
        const data = await response.json();

        // get related articles
        setRelatedArticles(data.relatedArticlesId);

        const updatedContent = await Promise.all(
          data.content.map(async (block) => {
            if (block.type === 'image') {
              const imageRef = storage.refFromURL(block.src);
              block.src = await imageRef.getDownloadURL();
            } else if (block.type === 'section') {
              block.content = await Promise.all(
                block.content.map(async (subBlock) => {
                  if (subBlock.type === 'image') {
                    const imageRef = storage.refFromURL(subBlock.src);
                    subBlock.src = await imageRef.getDownloadURL();
                  }
                  return subBlock;
                })
              );
            }
            return block;
          })
        );

        setBlog({ ...data, content: updatedContent });
      } catch (storageError) {
        console.error('Error fetching blog from storage:', storageError);
        setError('Blog post not found');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id]);

  const renderContentBlock = (block) => {
    switch (block.type) {
      case 'paragraph':
        return <p>{block.text}</p>;
      case 'image':
        const img = new Image();
        img.src = block.src;
        return (
          <figure>
            <img
              src={block.src}
              alt={block.alt}
              className={`blog-post-image ${
                block.isPortrait ? 'blog-post-image-portrait' : 'blog-post-image-landscape'
              }`}
            />
            <figcaption className="image-caption">{block.alt}</figcaption>
          </figure>
        );
      case 'section':
        return (
          <div key={block.title}>
            <h3>{block.title}</h3>
            {block.content.map((subBlock, index) => (
              <div key={index}>{renderContentBlock(subBlock)}</div>
            ))}
          </div>
        );
      case 'conclusion':
        return (
          <div className="conclusion">
            <h4>Conclusion</h4>
            <p>{block.text}</p>
          </div>
        );
      default:
        return null;
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {!loading &&
        <Helmet>
          <title>{blog.title} - Tensor Pilot Blog</title>
          <meta name="description" content={blog.introduction} />
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content={blog.introduction} />
          <meta property="og:image" content={blog.content.find(block => block.type === 'image')?.src} />
          <meta property="og:url" content={`https://www.aidronesoftware.com/blog/${id}`} />
          <meta property="og:type" content="article" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      }
      <NavBarMain />

      <Container fluid className="col-lg-8 col-md-10 col-11 blog-post-container mb-5">
        {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
          :
          (
            <>
              <Button variant="outline-primary" onClick={() => history.push('/blog')} className="mb-4">
                &larr; Back to all blogs
              </Button>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className="blog-post-full">
                    <center>
                      <h1 className="mb-3">{blog.title}</h1>
                    </center>
                    <p className="blog-post-date mb-3">Published on {blog.date}</p>
                    <div className="blog-post-content">
                      {blog.content.map((block, index) => (
                        <div key={index}>{renderContentBlock(block)}</div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              {relatedArticles?.length && <RelatedArticles relatedArticles={relatedArticles} />}
            </>
        )}
      </Container>
      <BlogPostBottom/>
    </>
  );
};

export default BlogPost;
