import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { faUniversalAccess, faLightbulb, faSmileBeam, faHandSparkles, faVideo, faGamepad, faGavel, faKeyboard, faCog, faRocket } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import PaidSubscriptionCard from './PaidSubscriptionCard';
import SubscriptionCardInfo from './SubscriptionCardInfo';


const Individual = ({ products, 
    handleClickForBuying, 
    handleDownloadClick, 
    loading, 
    clickedPriceId,
    userSubscription}) => {

  const freeFeatures = [
    { text: "AI - Object Detection", available: false, iconName: faUniversalAccess },
    { text: "AI - Human Pose Detection", available: false, iconName: faLightbulb },
    { text: "AI - Face Mesh", available: false, iconName: faSmileBeam },
    { text: "AI - Hand Gesture", available: false, iconName: faHandSparkles },
    { text: "Cinematography Modes", available: true, iconName: faVideo },
    { text: "Remote Control", available: true, iconName: faGamepad },
    { text: "Keyboard Control", available: true, iconName: faKeyboard },
    { text: "Non Commercial License", available: false, iconName: faGavel },
    { text: "Compatible with Apple", available: true, iconName: faApple },
    { text: "Compatible with Windows", available: true, iconName: faWindows },
    { text: "Software Upgrades", available: true, iconName: faCog },
    { text: "Tello Drone Compatible", available: true, iconName: faRocket },
  ];

  const proFeatures = [
    { text: "AI - Object Detection", available: true, iconName: faUniversalAccess },
    { text: "AI - Human Pose Detection", available: true, iconName: faLightbulb },
    { text: "AI - Face Mesh", available: true, iconName: faSmileBeam },
    { text: "AI - Hand Gesture", available: true, iconName: faHandSparkles },
    { text: "Cinematography Modes", available: true, iconName: faVideo },
    { text: "Remote Control", available: true, iconName: faGamepad },
    { text: "Keyboard Control", available: true, iconName: faKeyboard },
    { text: "Non Commercial License", available: false, iconName: faGavel },
    { text: "Compatible with Apple", available: true, iconName: faApple },
    { text: "Compatible with Windows", available: true, iconName: faWindows },
    { text: "Software Upgrades", available: true, iconName: faCog },
    { text: "Tello Drone Compatible", available: true, iconName: faRocket },
  ];
  return (
    <div id="individual" className="mt-2">
      <Row className="justify-content-center">
        <Col md={5} lg={5} sm={12} className="mt-2">
          <SubscriptionCardInfo
            title="Tensor Pilot Basic"
            description="Free to download, install and use"
            features={freeFeatures}
            onButtonClick={handleDownloadClick}
            buttonText="Download"
          />
        </Col>

        <Col md={5} lg={5} sm={12} className="mt-2">
          <PaidSubscriptionCard
            title="Tensor Pilot AI"
            features={proFeatures}
            products={products}
            onButtonClick={handleClickForBuying}
            popular={true}
            loading={loading}
            clickedPriceId={clickedPriceId}
            userSubscription={userSubscription}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Individual;
