import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faUniversalAccess, faLightbulb, faSmileBeam, faHandSparkles } from '@fortawesome/free-solid-svg-icons';

import ObjectDetection from './assets/ObjectDetection.png';
import HandLandMarkDetection from './assets/HandLandMarkDetection.png';
import HumanPoseDetection from './assets/HumanPoseDetection.png';
import FaceMesh from './assets/FaceMesh.png';

import './css/AIFeaturesDisplay.css';

// Define unique feature data with unique IDs
const features = [
    {
        id: 'object-detection',
        title: "Object Detection",
        description: "Real-time object detection capabilities to enhance your drone's operational efficiency. This feature allows drones to identify and categorize various objects during flight, facilitating immediate response and decision-making. Such technology is crucial for applications like delivery, surveillance, or environmental monitoring, where understanding the immediate environment is key.",
        icon: faLightbulb,
        color: '#FFD700', // Gold
        gif: ObjectDetection
    },
    {
        id: 'pose-detection',
        title: "Pose Detection",
        description: "Live pose detection is crucial for applications like crowd management, rescue operations, sports training, and event monitoring, where understanding human actions and reactions can greatly enhance operational effectiveness and safety. The technology provides precise body pose landmarks in image coordinates and 3-dimensional world coordinates, facilitating accurate assessments and interactions in dynamic environments.",
        icon: faUniversalAccess,
        color: '#4CAF50', // Green
        gif: HumanPoseDetection
    },
    {
        id: 'face-landmark-detection',
        title: "Face Landmark Detection",
        description: "Face detection allows drones to detect human faces and their key landmarks, enabling advanced functionalities like user identification, crowd monitoring, and interaction. This feature is particularly useful in managing complex flight environments and preventing collisions with people or other drones.",
        icon: faSmileBeam,
        color: '#2196F3', // Blue
        gif: FaceMesh
    },
    {
        id: 'hand-gesture-recognition',
        title: "Hand Gesture Recognition",
        description: "To empower your drone with real-time hand gesture recognition. This feature allows the drone to recognize and interpret specific hand gestures from users, enabling intuitive control and interaction without physical contact. The technology operates on image data, using a sophisticated ML model to provide results for recognized gestures along with detailed hand landmarks in both image and world coordinates. It also determines the handedness (left or right) of the detected hands. This functionality is perfect for user-drone interaction in applications such as remote control, instructional guidance, and interactive displays, enhancing usability and accessibility.",
        icon: faHandSparkles,
        color: '#FF5722', // Deep Orange
        gif: HandLandMarkDetection
    }
];

const AIFeaturesDisplay = () => {
    return (
        <div className="mt-5">
            <Row className='mb-4 feature-container text-center'>
                <Col>
                    <h3>Advanced AI Capabilities for your Drone</h3>
                    <p>Transform Drone Interactions with Our Advanced AI Technology</p>
                </Col>
            </Row>
            <Row className='mt-4'>
                {features.map((ai_feature, index) => (
                    <Col lg={12} md={12} sm={12} className='mb-2 mt-2' key={ai_feature.id}>
                        <Row 
                            id={ai_feature.id}
                            className="feature-container d-flex justify-content-center align-items-center"
                            style={{ 
                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                boxShadow: `0px 2px 4px ${ai_feature.color}`
                            }}
                        >   
                            <Col xs={12} sm={12} md={12} lg={12} xl={7} className='mb-2'>
                                <div className="image-container flex-fill">
                                    <img 
                                        src={ai_feature.gif} 
                                        alt={`${ai_feature.title} gif`} 
                                        className="rounded-img"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={5} className='mb-2'>
                                <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
                                    <div className="order-1 order-sm-1 mb-2">
                                        <FontAwesomeIcon icon={ai_feature.icon} size="3x" style={{ color: ai_feature.color }} />
                                    </div>
                                    <div className="order-2 order-sm-2">
                                        <h4 className="text-center">{ai_feature.title}</h4>
                                        <p className="text-justify">{ai_feature.description}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default AIFeaturesDisplay;
