import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faBullseye, faClock } from '@fortawesome/free-solid-svg-icons';
import './css/CinematographyFeatures.css'

const CinematographyFeatures = () => {
    const features = [
        {
            title: "Photo Mode",
            description: "Capture high-quality images with your drone's camera in Photo Mode.",
            icon: faCamera,
            tooltip: "Photo",
            color: '#FFD700', // Gold
        },
        {
            title: "Video Record Mode",
            description: "Record smooth and stable videos while flying with Video Record Mode.",
            icon: faVideo,
            tooltip: "Video Record",
            color: '#4CAF50', // Green
        },
        {
            title: "Burst Mode",
            description: "Take rapid sequence photos to capture every moment in Burst Mode.",
            icon: faBullseye,
            tooltip: "Burst Mode",
            color: '#2196F3', // Blue
        },
        {
            title: "Timer Mode",
            description: "Set a timer for delayed photo shots in Timer Mode.",
            icon: faClock,
            tooltip: "Timer",
            color: '#FF5722', // Deep Orange
        }
    ];


    return (
        <div className="cinematography-section mt-5">
            <Container>
                <center><h3>Cinematography Features</h3></center>
                <Row className="justify-content-center pt-3">
                    {features.map((feature, index) => (
                        <Col sm={12} md={12} lg={6} xl={3} xxl={3} key={index} className="mb-4 d-flex align-items-stretch">
                            <Card className="h-100 text-center shadow-sm">
                                <Card.Body>
                                    <div className="mb-3">
                                        <FontAwesomeIcon icon={feature.icon} size="2x" style={{ color: feature.color }} />
                                    </div>
                                    <Card.Title>{feature.title}</Card.Title>
                                    <Card.Text>{feature.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default CinematographyFeatures;
