import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConsentModal = ({ show, handleAccept, handleDecline }) => {
  const [showMore, setShowMore] = useState(false);

  const handleSeeMore = () => {
    setShowMore(true);
  };

  return (
    <Modal 
      show={show} 
      onHide={handleDecline} 
      centered 
      backdrop="static"  
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>We Value Your Privacy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showMore ? (
          <>
            <p>
              We use cookies and Google Analytics to ensure you have the best experience on our site. These tools help us 
              understand how our site is being used, enabling us to enhance your experience. Without your consent, some 
              features may not function properly, and your experience may be less personalized.
            </p>
            <p>
              By clicking "Accept", you allow us to track your interactions to improve site functionality and enhance 
              your experience. If you choose "Decline", certain features and functionalities may not be available to you.
            </p>
          </>
        ) : (
          <p>
            We use cookies and similar technologies to improve your experience. By clicking "Accept", 
            you consent to the use of these technologies for analytics and marketing purposes.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showMore ? (
          <>
          <span
              onClick={handleDecline}
              style={{ cursor: 'pointer', color: 'gray', marginLeft: '15px', fontSize:'15px' }}
            >
              Decline
            </span>
            
            <Button variant="primary" onClick={handleAccept}>
              Accept
            </Button>
          </>
        ) : (
          <>
            <Button variant="primary" onClick={handleAccept}>
              Accept
            </Button>
            <Button variant="link" onClick={handleSeeMore}>
              See More
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConsentModal;
