import React from 'react';
import { Container, Card } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import Helmet from 'react-helmet';
import './css/TermsAndConditions.css';
import Logo from '../../assets/Logo.png';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Terms and Conditions - Tensor Pilot</title>

        {/* Meta Description for SEO */}
        <meta name="description" content="Read the Terms and Conditions for using Tensor Pilot AI Drone Software. Understand your rights and responsibilities when accessing and using our drone software." />

        {/* SEO Keywords */}
        <meta name="keywords" content="Terms and Conditions, AI Drone Software Terms, Tensor Pilot Terms, Drone Software Legal, User Agreement, Software Usage Terms, Drone Software Policy, Terms of Service, AI Drone Legal, User Rights, Drone Software License" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Terms and Conditions - Tensor Pilot AI Drone Software" />
        <meta property="og:description" content="Review the Terms and Conditions for Tensor Pilot AI Drone Software. Learn about the policies governing the use of our software and your responsibilities as a user." />
        <meta property="og:url" content="https://www.aidronesoftware.com/terms-and-conditions" />
        <meta property="og:site_name" content="Tensor Pilot - AI Drone Software" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Terms and Conditions - Tensor Pilot an AI Drone Software" />
        <meta name="twitter:description" content="Review the Terms and Conditions for using Tensor Pilot AI Drone Software, including policies and user responsibilities." />
        <meta name="twitter:image" content={Logo} /> 

        {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <NavBarMain />
      <Container className="terms-container">
        <Card className="terms-card">
          <Card.Body>
            <Card.Title>
                <h2>Terms and Conditions</h2>
            </Card.Title>
            <Card.Text>
              <h5>1. Introduction</h5>
              <p>
                Welcome to AI Drone Software. These Terms and Conditions govern your use of our software, website, and services. By accessing or using our services, you agree to comply with these terms.
              </p>
              <h5>2. Use of Services</h5>
              <p>
                You must follow any policies made available to you within the services. Do not misuse our services, and use them only as permitted by law.
              </p>
              <h5>3. Privacy</h5>
              <p>
                Our Privacy Policy explains how we handle your personal data and protect your privacy when you use our services. By using our services, you agree that we can use such data in accordance with our privacy policies.
              </p>
              <h5>4. User Accounts</h5>
              <p>
                To use our services, you may be required to create an account. You are responsible for keeping your account information confidential and for any activities that occur under your account.
              </p>
              <h5>5. Software Updates</h5>
              <p>
                The software may update automatically from time to time. You agree to receive such updates as part of your use of the services.
              </p>
              <h5>6. Termination</h5>
              <p>
                We may suspend or terminate your access to our services if you do not comply with these terms or if we are investigating suspected misconduct.
              </p>
              <h5>7. Limitation of Liability</h5>
              <p>
                To the fullest extent permitted by law, AI Drone Software shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services.
              </p>
              <h5>8. Governing Law</h5>
              <p>
                These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is established, without regard to its conflict of law principles.
              </p>
              <h5>9. Changes to Terms</h5>
              <p>
                We may modify these terms or any additional terms that apply to our services. You should look at the terms regularly. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted.
              </p>
              <h5>10. Contact Information</h5>
              <p>
                If you have any questions about these Terms and Conditions, please contact us at support@aidronesoftware.com.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default TermsAndConditions;
