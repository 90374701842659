import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faGamepad } from '@fortawesome/free-solid-svg-icons';
import ControlsMenu from './ControlsMenu.js';
import './css/DroneControlOptions.css';

const DroneControlOptions = () => {
    const [selectedControl, setSelectedControl] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleSelectControl = (control) => {
        setSelectedControl(control);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedControl(null);
    };

    return (
        <div className="control-options-section mt-5">
            <Container>
                <h3 className="text-center mb-1">Multiple Ways to Control Your Drone</h3>
                <p className="text-center mb-3">
                    Our drone software supports advanced control options through both a gamepad and a keyboard. 
                    This dual-control system offers enhanced flexibility and precision, catering to different user preferences and operation scenarios.
                </p>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-4">
                        <Card className="feature-card h-100 text-center shadow-sm">
                            <Card.Body>
                                <FontAwesomeIcon 
                                    icon={faKeyboard} 
                                    size="3x" 
                                    className="feature-icon mb-3" 
                                    onClick={() => handleSelectControl('Keyboard')}
                                />
                                <Card.Title>Keyboard Control</Card.Title>
                                <Card.Text className='text-justify'>
                                    Perfect for precise maneuvers, the keyboard control allows operators to execute specific commands quickly and accurately. Ideal for experienced users who prefer tactile feedback and quick access to multiple functions without looking away from the operation screen.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-4">
                        <Card className="feature-card h-100 text-center shadow-sm">
                            <Card.Body>
                                <FontAwesomeIcon 
                                    icon={faGamepad} 
                                    size="3x" 
                                    className="feature-icon mb-3"
                                    onClick={() => handleSelectControl('Gamepad')} 
                                />
                                <Card.Title>Gamepad Control</Card.Title>
                                <Card.Text className='text-justify'>
                                    Offering a familiar and intuitive interface, the gamepad control enhances the flying experience with ergonomic comfort and smooth handling. It's especially suited for prolonged operations and when precise, gradual movements are crucial, such as during filming or detailed inspections.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Modal show={showModal} onHide={handleClose} size="lg" className="custom-modal">
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title>{selectedControl} Controls</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-modal-body">
                        {selectedControl && <ControlsMenu selectedControl={selectedControl} />}
                    </Modal.Body>
                    <Modal.Footer className="custom-modal-footer">
                        <Button variant="secondary" onClick={handleClose} className="custom-close-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
};

export default DroneControlOptions;
