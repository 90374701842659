import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import { faFacebook, faInstagram, faLinkedin, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import Logo from '../../assets/Logo.png';

const Footer = () => {
    return (
        <div className="footer-container">
            <Container>
                <Row>
                    <Col xs={12} md={3} className="footer-section">
                        <div className="footer-logo">
                            <img 
                                className="navbar-logo"
                                src={Logo} 
                                alt="Drone AI Logo"
                            />
                            <p>© 2024 AI Drone Software</p>
                            <p>support@aidronesoftware.com</p>
                        </div>
                        <div className="social-icons">
                            {/* <FontAwesomeIcon icon={faXTwitter} /> */}
                            <FontAwesomeIcon size="2x"
                                className="mr-1 fa-icon" 
                                icon={faInstagram} 
                            />
                            
                            <a 
                                href="https://www.linkedin.com/company/tensor-pilot" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                
                            >
                                <FontAwesomeIcon 
                                    size="2x" 
                                    className="mr-1 fa-icon" 
                                    icon={faLinkedin} 
                                />
                            </a>

                            <a 
                                href="https://www.youtube.com/@tensor-pilot" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                            >
                                <FontAwesomeIcon size="2x"
                                className="mr-1 fa-icon" 
                                icon={faYoutube} 
                                />
                            </a>

                            
                        </div>
                    </Col>
                    <Col xs={0} md={2} className="footer-section">
                    </Col>
                    <Col xs={6} md={2} className="footer-section">
                        <h5>Product</h5>
                        <ul className="list-unstyled">
                            <li><a href="/profile">Download</a></li>
                        </ul>
                    </Col>
                    {/* <Col xs={6} md={2} className="footer-section">
                        <h5>Company</h5>
                        <ul className="list-unstyled">
                            <li><a href="/jobs">Jobs</a></li>
                            <li><a href="/newsroom">Newsroom</a></li>
                        </ul>
                    </Col> */}
                    <Col xs={6} md={2} className="footer-section">
                        <h5>Resources</h5>
                        <ul className="list-unstyled">
                            {/* <li><a href="/support">Support</a></li>
                            <li><a href="/safety">Safety</a></li> */}
                            {/* <li><a href="/blog">Blog</a></li> */}
                            <li><a href="/contactus">Contact Us</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} className="footer-section">
                        <h5>Policies</h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                    Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="footer-bottom text-center">
                    <Col xs={12} md={8} lg={8}>
                        
                    </Col>
                    <Col xs={6} md={2} lg={2}>
                        <Button 
                            variant="outline-primary" 
                            href="/login" 
                            className="footer-button">
                                Login
                        </Button>
                    </Col>
                    <Col xs={6} md={2} lg={2}>
                        <Button 
                        variant="outline-primary" 
                        href="/signup" 
                        className="footer-button">
                            Sign Up
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
